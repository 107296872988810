*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  font-family: var(--font-openSans);
}
html,
body {
  height: 100%;
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-openSans);
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}
